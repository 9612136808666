define('app/forms',["app/config","jquery","app/util","templates","app/features/modal"], function(config,$,util,templates,modal) {
    return {
        name: 'forms',
        selectors: false,
        remoteData: [],

        config: {
            // TODO - This is project specific config, we need to wrap this up as part of manifests
            inlineErrors: true,
            responseLocation: '#centerMenuContent .bv-form' // false
            //inlineErrorForms: ['#login']
        },

        initialize: function() {
            this.refreshFormHandlers();
        },

        // FORM COMPONENTS
        formComponents: [{
            fn: 'doLogin',
            prepUrl: function() {
                var url = config.baseUrl+'/pages/auth.aspx?method=login&' + $('#login input').serialize();
                return url;
            },
            formComponent: '#login',
            validation: {
                fields: {
                    email: {
                        trigger: 'input change',
                        validators: {
                            notEmpty: {
                                message: '<span class="msg">Email is Required</span>'
                            },
                            emailAddress: {
                                message: '<span class="msg">The input is not a valid email address.</span>'
                            }
                        }
                    },
                    password: {
                        validators: {
                            notEmpty: {
                                message: '<span class="msg">Password is Required.</span>'
                            }
                        }
                    }
                }
            },
            submitButton: '#signinButton'
        },{
            fn: 'doRegister',
            prepUrl: function() {
                var url = config.baseUrl+'/pages/auth.aspx?method=register&' + $('#register input').serialize();
                return url;
            },
            formComponent: '#register',
            submitButton: '#submitRegisterBtn',
            validation: {
                fields: {
                    fname: {
                        validators: {
                            notEmpty: {
                                message: '<span class="msg">First Name is Required.</span>'
                            }
                        }
                    },
                    lname: {
                        validators: {
                            notEmpty: {
                                message: '<span class="msg">Last Name is Required.</span>'
                            }
                        }
                    },
                    email: {
                        validators: {
                            notEmpty: {
                                message: '<span class="msg">Email is Required.</span>'
                            },
                            emailAddress: {
                                message: '<span class="msg">The input is not a valid email address.</span>'
                            }
                        }
                    },
                    password: {
                        validators: {
                            stringLength: {
                                min: 6,
                                message: '<span class="msg">Please enter more than 6 characters.</span>'
                            },
                            notEmpty: {
                                message: '<span class="msg">Password is Required.</span>'
                            }
                        }
                    },
                    chkAgree: {
                        validators: {
                            notEmpty: {
                                message: '<span class="msg">Please accept Terms & Conditions.</span>'
                            }
                        }
                    }
                }
            }
        },{
            fn: 'doForgotPassword',
            prepUrl: function() {
                var url = config.baseUrl+'/pages/auth.aspx?method=password_request&' + $('#forgotPassword input').serialize();
                return url;
            },
            validation: {
                fields: {
                    email: {
                        validators: {
                            notEmpty: {
                                message: '<span class="msg">Email is Required.</span>'
                            },
                            emailAddress: {
                                message: '<span class="msg">The input is not a valid email address.</span>'
                            }
                        }
                    }
                }
            },
            formComponent: '#forgotPassword',
            submitButton: '#submitForgotBtn'
        },{
            fn: 'doResetPassword',
            prepUrl: function() {
                var url = config.baseUrl + '/pages/auth.aspx?method=password_reset&token=' + util.getParameterByName('token') + '&' + $('#resetPassword input').serialize();
                return url;
            },
            validation: {
                fields: {
                    email: {
                        validators: {
                            notEmpty: {
                                message: '<span class="msg">Email is Required.</span>'
                            },
                            emailAddress: {
                                message: '<span class="msg">The input is not a valid email address.</span>'
                            }
                        }
                    },
                    password: {
                        validators: {
                            stringLength: {
                                min: 6,
                                message: '<span class="msg">Please enter more than 6 characters.</span>'
                            },
                            notEmpty: {
                                message: '<span class="msg">Password is Required.</span>'
                            }
                        }
                    }
                }
            },
            formComponent: '#resetPassword',
            submitButton: '#resetPasswordButton'
        }],

        /*
         * Form Action Methods
         */
        methods: {

            // User login method
            doLogin: function(url,formEl) {
                util.setLoading(true,false,formEl);
                $('.form.login').addClass('loggingIn');
                $.post(url,$.proxy(function (data) {
                    $.proxy(this.showResponseMessage(JSON.parse(data),this));
                },this)).fail($.proxy(this.onPostFail,this)).always(function(){
                    util.setLoading(false,false,formEl);
                });
            },

            doRegister: function(url,formEl) {
                $('#actionMenu').removeClass('hasMessage');
                util.setLoading(true,false,formEl);
                $.post(url,$.proxy(function (data) {
                    var parsedData = JSON.parse(data);
                    if(parsedData.hasError != "true") {
                        if(window['fbq'] && window.fbq['instance']) {
                            window.fbq('track', 'CompleteRegistration');
                        }
                    }
                    $.proxy(this.showResponseMessage(parsedData,this));
                },this)).fail($.proxy(this.onPostFail,this)).always(function(){
                    util.setLoading(false,false,formEl);
                });
            },

            doForgotPassword: function(url,formEl) {
                util.setLoading(true,false,formEl);
                $.post(url, $.proxy(function (data) {
                    $.proxy(this.showResponseMessage(JSON.parse(data),this));
                },this)).fail($.proxy(this.onPostFail,this)).always(function(){
                    util.setLoading(false,false,formEl);
                });
            },

            doResetPassword: function(url,formEl) {
                $('#actionMenu').removeClass('hasMessage');
                util.setLoading(true,false,formEl);
                $.post(url,$.proxy(function (data) {
                    $.proxy(this.showResponseMessage(JSON.parse(data),this));
                },this)).fail($.proxy(this.onPostFail,this)).always(function(){
                    util.setLoading(false,false,formEl);
                });
            }
        },

        /*
         * Generic methods
         */

        refreshFormHandlers: function() {

            $.each(this.formComponents,$.proxy(function(index,value){

                $('.form input').focus(function(){
                    $(this).parents('.form').find('.firstLoad').removeClass('firstLoad');
                });

                // Listener for submit/enter
                $(value.submitButton).click($.proxy(function(e){
                    e.stopImmediatePropagation();
                    e.preventDefault();
                    this.methods[value.fn].call(this,value.prepUrl(),value.formComponent);
                },this));
                $(value.formComponent).find('input').on('keydown',function (e) {
                    e.stopImmediatePropagation();
                    if ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13)) {
                        e.preventDefault();
                        if($(value.formComponent).data('bootstrapValidator').isValid()) {
                            $(value.submitButton).trigger('click');
                        }
                    } else {
                        return true;
                    }
                });

                // Validation listening
                $(value.formComponent).bootstrapValidator($.merge((value.validation || {}),{
                    trigger: 'change input'
                })).on('status.field.bv', function(e, data) {
                    data.bv.disableSubmitButtons(!data.bv.isValid());
                    $(value.submitButton).toggleClass('btn-warning',!data.bv.isValid())
                });

                // Prepopulate any fields from config on the register form
                if(value.formComponent == '#register' && config.regConfig) {
                    $.each(config.regConfig,function(n,v){
                        $('#register input[name="'+n+'"]').attr('value',v).val(v);
                    });
                }

                // Check if any fields are pre-populated, if so validate them
                if($(value.formComponent).data('bootstrapValidator') && $(value.formComponent).find('input[value]').length > 0) {
                    $(value.formComponent).find('input[value]').filter(function(){return this.value;}).each(function(){
                        $(value.formComponent).data('bootstrapValidator').validateField($(this));
                    });
                }

            },this));
        },

        showResponseMessage: function(message) {

            var hasError = (message.hasError === "true"); // Convert to true boolean
            message.originForm = $(this.config.responseLocation).attr('id');
            message.hasError = hasError;

            // Redirect if necessary
            if (message.sRedirect && message.sRedirect.length > 0) {
                setTimeout(function(){
                    location.href = message.sRedirect;
                },500); // Slight delay
                return;
            }

            // Display error or success message
            if(this.config.responseLocation) {
                if(hasError && this.config.inlineErrors) {
                    // TODO - Refine this scoping for .validationMessage class
                    $('.validationMessage').html(message.message).toggleClass('has-warning',true);
                } else {
                    $('.validationMessage').hide().toggleClass('has-warning',hasError);
                    $(this.config.responseLocation).addClass('hasMessage');
                    $(this.config.responseLocation).replaceWith(templates.validationmessage(message));
                    if(!hasError) {
                        $(this.config.responseLocation).addClass('largeSuccess');
                    }
                }
            } else if($('#actionMenu').length > 0) {
                // If we are using the dashboard menu
                $('#actionMenu').addClass('hasMessage');
                $('.validationMessage').html(templates.validationmessage(message));
                if(!hasError) {
                    $('#actionMenu').addClass('largeSuccess');
                }
                $('.validationMessage').toggleClass('has-warning',hasError);
            } else if($('.modalActionWrap').length > 0) {
                // If we are using modals
                // Check and apply inline errors
                if(this.config.inlineErrors && hasError && message.message.length < 105) {
                    modal.getEl().find('.inline-message').html(message.message).show();
                } else {
                    modal.refreshContent(templates.formmessage(message),true);
                }
            }
        },

        onPostFail: function(err) {
            // TODO - Implement critical error tracking
            console.debug(arguments);
        }
    }
});
