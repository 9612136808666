define('modules/faqs/horizontal/js/base.js',["app/module","app/config","jquery","app/util","templates","jquery.livequery"], function(module,config,$,util,templates) {
    return new module({

        name: 'faqs',
        selectors: '.faqs',
        remoteData: [config.urls.faqs],

        config: {
        },

        initialize: function (data) {
            this.$container = $(this.getSelectors());

            this.$container.find('.faqWrap').append(templates.faqsliderhorizontal({
                records: data
            }));

            this.addEventHandlers();
            this.loadCards(1, false);
        },

        methods: {

            addEventHandlers: function () {
                this.$container.on("click", ".cards", $.proxy(function(ev) {
                    ev.preventDefault();
                    ev.stopImmediatePropagation();

                    this.$container.find(".cards").css('pointer-events','none');

                    var clickedNode = ev.currentTarget.classList;
                    var detectSwipeDirectionClass = clickedNode[clickedNode.length - 1];
                    var cardId = $(ev.currentTarget).data("card-num");
                    switch(detectSwipeDirectionClass){
                        case "prevFar":
                            this.loadCards(cardId+1, true);
                            setTimeout($.proxy(function(){
                                this.loadCards(cardId, true)
                            },this), 600);
                        break;
                        case "prev":
                            this.loadCards(cardId, true);
                        break;
                        case "next":
                            this.loadCards(cardId, false);
                        break;
                        case "nextFar":
                            this.loadCards(cardId-1, false);
                            setTimeout($.proxy(function(){
                                this.loadCards(cardId, false)
                            },this), 600);
                        break;
                        default:
                            this.loadCards(cardId);
                    }
                    
                    setTimeout($.proxy(function(){
                        this.$container.find(".cards").css('pointer-events','initial');
                    }, this), 1000);

                }, this));



                this.$container.on("click", ".cards .answer-inner a", $.proxy(function(ev) {
                    ev.stopImmediatePropagation();
                }, this));

                this.$container.find('.faq-cards-mobile-indicators i').on('click',$.proxy(function(e){
                   e.preventDefault();
                   this.$container.find('.cards').eq($(e.currentTarget).index()).trigger('click');
                },this));

                this.$container.find("#faqsHorizontalCarouselRightArrow").on("click", $.proxy(function(ev){
                    this.$container.find(".next").trigger("click");
                }, this));
                this.$container.find("#faqsHorizontalCarouselLeftArrow").on("click", $.proxy(function(ev){
                    this.$container.find(".prev").trigger("click");
                }, this));

                // touch events
                if($("html").hasClass("touch")){
                    this.$container.find(".faqInnerWrap")
                    .on("swipeleft", $.proxy(function(ev){
                        ev.preventDefault();
                        ev.stopImmediatePropagation();
                        this.$container.find(".next").trigger("click");
                    }, this))
                    .on("swiperight", $.proxy(function(ev){
                        ev.preventDefault();
                        ev.stopImmediatePropagation();
                        this.$container.find(".prev").trigger("click");
                    }, this));
                }

                this.$container.find('.btn-more').on('click', function(e) {
                    e.stopImmediatePropagation();
                    if ($(this).closest('.cards').hasClass('currentCard')) {
                        $(this).closest('.currentCard').toggleClass('card-content-additional-shown');
                    }
                });
            },

            loadCards: function (currentCardNumber, leftSwipe) {

                var $allCards = this.$container.find(".cards");
                $allCards.addClass("hideCard");

                var $currentCard = this.$container.find(".cards[data-card-num='" + currentCardNumber + "']");

                leftSwipe = (leftSwipe === undefined ? ($currentCard.index() < this.$container.find('.currentCard').index()) : leftSwipe);

                this.$container.find('.faq-cards-mobile-indicators i').removeClass('active').eq($currentCard.index()).addClass('active');

                if (leftSwipe) {
                    $currentCard
                        .prevOrLast(".cards").attr("class",'cards animated slideOutLeft notCurrentCard prev') //Previous Card
                        .prevOrLast(".cards").attr("class",'cards animated slideOutLeftFar notCurrentCard prevFar'); //Previous Card far
                    $currentCard.attr("class",'cards currentCard animated slideOutCenterLeft'); //Current Card
                    $currentCard
                        .nextOrFirst(".cards").attr("class",'cards animated slideOutRight notCurrentCard next') //Next Card
                        .nextOrFirst(".cards").attr("class",'cards animated slideOutRightFar notCurrentCard nextFar'); //Next Card far
                } else {
                    $currentCard
                        .prevOrLast(".cards").attr("class",'cards animated reverseSlideOutLeft notCurrentCard prev') //Previous Card
                        .prevOrLast(".cards").attr("class",'cards animated reverseSlideOutLeftFar notCurrentCard prevFar'); //Previous Card far
                    $currentCard.attr("class",'cards currentCard animated reverseSlideOutCenterLeft'); //Current Card
                    $currentCard
                        .nextOrFirst(".cards").attr("class",'cards animated reverseSlideOutRight notCurrentCard next') //Next Card
                        .nextOrFirst(".cards").attr("class",'cards animated reverseSlideOutRightFar notCurrentCard nextFar'); //Next Card far
                }
            }
        }
    });
});

