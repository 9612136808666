define('modules/graphicmapmenu/js/base.js',["app/module","app/config","app/user","jquery","app/util","templates","app/forms","app/features/video","jquery.cookie","bootstrap/popover"], function(module,config,user,$,util,templates,forms,videoFeature) {
    return new module({
        name: 'graphicmapmenu',
        selectors: '.mainMenuWrap',
        remoteData: [config.urls.sidebar],

        config: {
            formLocation: null,
            hideMenuOnForms: false,
            openRoutes: ['register','login','forgot','reset'], // Default for forms
            menuItemPositions: [{
                x: 20, // News
                y: 72
            },{
                x: 22, // About
                y: 25
            },{
                x: 60, // Timeline
                y: 82
            },{
                x: 60, // Application
                y: 50
            },{
                x: 62, // Judges
                y: 24
            },{
                x: 70, // Rules
                y: 66
            },{
                x: 28, // Scoring
                y: 44
            },{ // From here and below are fallbacks, not in the design
                x: 50,
                y: 50
            },{
                x: 60,
                y: 60
            },{
                x: 70,
                y: 70
            }]
        },

        routes: [{
            route: '.*',
            fn: function(hash){
                if(!this.getConfig().formLocation) {
                    this.updateToggleMenu(false);
                } else if(this.getConfig().openRoutes.indexOf(hash.split('/')[0]) == -1) {
                    if(this.appLoaded) {
                        this.updateToggleMenu(false);
                    } else {
                        this.appLoaded = true;
                    }
                }
            },
            extra: true,
            delay: 150
        },{
            route: 'register',
            fn: 'routeRegister',
            silent: true,
            delay: 200
        },{
            route: 'login',
            fn: 'routeLogin',
            silent: true,
            delay: 200
        },{
            route: 'forgot',
            fn: 'routeForgot',
            silent: true,
            delay: 200
        },{
            route: 'reset',
            fn: 'routeReset',
            silent: true,
            delay: 200
        }],

        initialize: function (data) {

            // Render the template
            $('.mainMenuWrap').append(templates.graphicmapmenu({
                items: data,
                itemPositions: this.getConfig().menuItemPositions,
                submissionClosed: config.rampitRegTimeLeft == 0
            }));

            if(this.getConfig().hideMenuOnForms) {
                $(this.getConfig().formLocation).hide();
            }

            var mainMenu = $('#mainMenu');

            // Only applies if the mainMenu is present
            if(mainMenu.length > 0) {

                if(user.checkRole('loggedin')) {
                    if(!this.getConfig().hideMenuOnForms) {
                        this.updateToggleMenu(templates.dashboardlinks());
                        $('#actionMenu').toggleClass('dashboardLinksShown',true);
                    }
                    // If the user is logged in, update the days left to submit and logout link
                    $('#mainNavbar a[href*="login"]').text('Logout').attr('href','/logout.aspx');
                    if(config.rampitSubTimeLeft >= 0) {
                        $('#mainNavbar a[href*="register"]').html('<span class="dayLeft"></span> Days to Submit').attr('href','#');
                    }
                } else if(user.checkRole('registered')) {
                    if(!this.getConfig().hideMenuOnForms) {
                        this.updateToggleMenu(templates.login(config.regConfig));
                        $('#actionMenu').toggleClass('loginShown',true);
                    }
                } else {
                    if(!this.getConfig().hideMenuOnForms) {
                        this.updateToggleMenu(templates.register(config.regConfig));
                        $('#actionMenu').toggleClass('registerShown',true);
                    }
                }

                // Detect and handle close button
                $('.dashboardToggle, #mainMenu .closeWrap').on('click',$.proxy(function(e){
                    e.preventDefault();
                    this.updateToggleMenu(!$('#mainMenu').is(':visible'),false);
                    return false;
                },this));

                // Add body listener for menu status control
                $('body').on('updatedashboard',$.proxy(function(e,a,b,c){
                    this.updateToggleMenu(a,b,c);
                },this));

            }

            // Handle the hash routing for the main menu
            // TODO - Important - we need to reimplement this into routing methods and also handle situations where both modals and navmenu are being used
            $(window).hashchange($.proxy(function () {
                if (window.bRampit) {
                    rampitInit(location.hash.substr(1));
                }
            }, this));

            $(window).smartresize($.proxy(function(){
                if($(window).width() < 768) {
                    $(this.getSelectors()).find('#sidebar .sidebar-item-wrap').insertAfter($(this.getSelectors()).find('.sidebar-item-layer > .sidebar-item').eq(3));
                } else {
                    $(this.getSelectors()).find('#sidebar .sidebar-item-wrap').insertAfter($(this.getSelectors()).find('.sidebar-item-layer > .sidebar-item').last());
                }
                this.calculateMenuItemPositions();
            },this));
        },

        onRender: function() {
            if(this.pendingRoute) {
                this.pendingRoute();
                this.pendingRoute = null;
            }
        },

        methods: {

            // This method is new for this module
            calculateMenuItemPositions: function() {
                var positions = this.getConfig().menuItemPositions,
                    width = $(this.getSelectors()).find('#sideMenu').width(), // Uses inner width
                    height = $(this.getSelectors()).find('#sideMenu').height();
                $.each(positions,function(i,v){
                    $('#sidebar .sidebar-item-layer > *').eq(i).css({
                        left: Math.floor((v.x/100)*width),
                        top: Math.floor((v.y/100)*height)
                    })
                });
            },


            updateToggleMenu: function (open, content, location) {

                // Check if we are just passing content, in which case do it silently
                if (typeof open == 'string' || typeof open == 'object') {
                    content = open;
                    open = null;
                }

                // Fire a global app event for open/close status
                if (open) {
                    $('body').trigger('mainnavopen');
                } else {
                    $('body').trigger('mainnavclose');
                }

                if(this.getConfig().hideMenuOnForms && this.getConfig().formLocation) {
                    $(this.getConfig().formLocation).hide();
                    $('#sideMenu').show();
                }


                // Reset visible elements
                $('#mainMenu .actionContent').show(); //, #mainMenu .daysPromo').show();
                $('#actionMenu').removeClass('hasMessage largeSuccess');

                // Set the template content
                if (content) {
                    var location = this.getConfig()['formLocation'];
                    if(location) {
                        $(location).html(content);
                        $(location).show();
                    } else {
                        $('#mainMenu .actionContent').html(content);
                    }
                } else {
                    $('#actionMenu').removeClass('loginShown registerShown');
                }

                // Trigger the opening animation
                if (open !== null) {
                    if (open == false) {
                        $('.navbarWrap').removeClass('move');
                        $('.navbar .navbar-header').removeClass('hideNav');
                        $('#mainMenu').addClass('closing');
                        $('#mainMenu').removeClass('open');
                        //$('.navbarWrap').addClass('slideUpOut');
                        $('.daysToRegister').removeClass('mobileNavVisible');
                        $('.hamburger-to-close').removeClass('changeclose');
                        $('.dashboardToggle').removeClass('open');
                        $('.sidebar-item').removeClass('sidebar-item-selected');
                        $('.dashboard-footer-menu').fadeOut();
                        $('.dashboard-menu-text').text('Menu');
                        $('.singlePageWrap').removeClass('pushContentLeft pushContentRight');
                        $('#centerMenuContent').removeClass('sidebarPage');
                        $('body').removeClass('locked');
                        //if(!$('html').hasClass('touch')) {
                            util.lockScrolling(false, '#sideMenu .menuWrap');
                        //}
                        setTimeout($.proxy(function () {
                            $('#mainMenu').hide();
                            $('#mainMenu').removeClass('closing');
                            if(this.getConfig().openRoutes.indexOf(window.location.hash.substr(1).split('/')[0]) > -1) {
                                window.location.hash = '';
                            }
                        }, this),1000);
                    } else {

                        if($('#mainMenu').hasClass('closing')) {
                            return;
                        }

                        $('#mainMenu').show();
                        $('.dashboard-footer-menu').fadeIn();
                        $('body').addClass('locked');
                        $('.dashboardToggle').addClass('open');
                        $('.dashboard-menu-text').text('close');
                        $('.daysToRegister').addClass('mobileNavVisible');
                        $('.hamburger-to-close').addClass('changeclose');
                        $('.navbarWrap').addClass('move');
                        $('.navbar .navbar-header').addClass('hideNav');
                        if(!$('html').hasClass('touch')) {
                            util.lockScrolling(true, '#sideMenu .menuWrap');
                        }
                        setTimeout($.proxy(function () {
                            this.calculateMenuItemPositions();
                            $('#mainMenu').addClass('open');
                        }, this), 30);
                    }
                }

                videoFeature.pauseAllVideos();

                forms.refreshFormHandlers();

            },

            routeRegister: function () {

                if (user.checkRole('loggedin')) {
                    this.updateToggleMenu(true);
                    return;
                }

                if(!this.isReady()) {
                    this.pendingRoute = this.routeRegister;
                    return;
                }

                $('#actionMenu').toggleClass('loginShown hasMessage largeSuccess', false);
                $('#sideMenu').addClass('mobAway');

                if (config.rampitRegTimeLeft < 1) {
                    this.updateToggleMenu(true, templates.validationmessage({
                        message: (config.regConfig ? (config.regConfig.closeMessage || '') : ''),
                        messageTitle: (config.regConfig ? (config.regConfig.closeTitle || '') : ''),
                        originForm: 'register',
                        customActionPanel: {
                            title: 'Register',
                            message: 'Registration Closed'
                        }
                    }));
                    $('#actionMenu').toggleClass('regClosed', true);
                } else {
                    this.updateToggleMenu(true, templates.register(config.regConfig));
                    $('#actionMenu').toggleClass('registerShown', true);
                }
                this.setFormComponent();
                this.closeLoginRegister();
                
            },

            routeLogin: function () {

                if (user.checkRole('loggedin')) {
                    this.updateToggleMenu(true);
                    return;
                }

                if(!this.isReady()) {
                    this.pendingRoute = this.routeLogin;
                    return;
                }

                $('#actionMenu').toggleClass('registerShown hasMessage largeSuccess', false);
                $('#actionMenu').toggleClass('loginShown', true);
                $('#sideMenu').addClass('mobAway');

                if (config.regConfig.type == 'expired') {
                    this.updateToggleMenu(true, templates.validationmessage({
                        title: 'Link Expired',
                        message: 'Please issue your request again.',
                        originForm: 'login',
                        hasError: true,
                        customActionPanel: {
                            title: 'Login',
                            message: 'To complete/view your application, please login.'
                        }
                    }));
                } else if (util.getParameterByName('token').length) {
                    this.updateToggleMenu(true, templates.resetpassword()); // TODO - Update this to use the module form
                } else {
                    this.updateToggleMenu(true, templates.login(config.regConfig));
                }
                this.setFormComponent();
                this.closeLoginRegister();

            },


            routeForgot: function () {

                if (user.checkRole('loggedin')) {
                    this.updateToggleMenu(true);
                    return;
                }


                if(!this.isReady()) {
                    this.pendingRoute = this.routeForgot;
                    return;
                }

                $('#sideMenu').addClass('mobAway');
                this.updateToggleMenu(true, templates.forgotpassword());
                this.setFormComponent();
                this.closeLoginRegister();
            },

            routeReset: function () {

                if(!this.isReady()) {
                    this.pendingRoute = this.routeReset;
                    return;
                }

                $('#sideMenu').addClass('mobAway');
                this.updateToggleMenu(true, templates.resetpassword());
                this.setFormComponent();
                this.closeLoginRegister();
            },

            setFormComponent: function() {
                if(this.getConfig().hideMenuOnForms) {
                    $('#sideMenu').hide();
                    $('#centerMenuContent').css('width','auto');
                }
            },
            
            closeLoginRegister: function() {
            	$('#mainMenu .register-login-forms-close').on('click',$.proxy(function(e){
                	$('#mainMenu .closeWrap').trigger('click');
                },this));
            }
        }
    });
});
