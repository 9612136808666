define('app/testing',['jquery','app/util'],function($,util){
    return {
        init: function() {
            //>>includeStart("testing",pragmas.testing);
            // Usersnap - Embedded version (will not work if site is behind htaccess auth)
            (function () {
                var s = document.createElement("script");
                s.type = "text/javascript";
                s.async = true;
                s.src = '//api.usersnap.com/load/' +
                    'fd78fe94-a468-465b-9e2e-ea841fa89025.js'; // TODO - This is per project key from Usersnap, need to set this up in manifest/config
                var x = document.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            })();
            if(util.getBuildConfig() && util.getBuildConfig()['buildTime']) {
                $('body').append('<div style="z-index:2147483636;position:fixed;bottom:30px;right:30px;text-align:right;background:rgba(52,73,94,0.5);color:white;font-size:10px;line-height:10px;padding:3px;">Last Build: '+new Date(util.getBuildConfig().buildTime).toLocaleString()+'</div>');
            }
            //>>includeEnd("testing");
        }
    };
});
