define('modules/process/verticalcard/js/base.js',["app/module", "app/config","jquery","app/util","templates"], function(module, config,$,util,templates) {
    return new module({
        name: 'process',
        selectors: '.processStepWrap',
        remoteData: [config.urls.process],


        initialize: function (data) {
			this.renderTemplates(data);
            this.addClickHandlers();
        },

        methods:{
            renderTemplates: function(data) {
                $.each(data,function(i,r){
                    data[i].total = data.length;
                });
                $(this.getSelectors()).append(templates.processsteps({
                    steps: data.reverse()
                }));
            },

            addClickHandlers: function() {
                $(this.getSelectors()).find('.process-step-next-wrap').on('click',function(e){
                    e.preventDefault();
                    $(this).parents('.process-step-card').removeClass('step-card-in').addClass('step-card-out');
                    $(this).parents('.process-step-card').prev('.process-step-card').addClass('step-card-in');
                    setTimeout($.proxy(function(){
                        $(this).parents('.process-step-card').parent().prepend($(this).parents('.process-step-card'));
                    },this),250);
                    setTimeout($.proxy(function(){
                        $('.process-step-card.step-card-out').removeClass('step-card-out');
                    },this),250);
                });
                $(this.getSelectors()).find('.process-controls .process-control-prev').on('click',function(e){
                    e.preventDefault();
                    $(this).closest('.cards-container').find('.process-step-card:first-of-type').parent().append($(this).closest('.cards-container').find('.process-step-card:first-of-type'));
                    $(this).closest('.cards-container').find('.process-step-card:last-of-type').removeClass('step-card-in').addClass('step-card-out-reverse');
                    $(this).closest('.cards-container').find('.process-step-card:nth-last-of-type(2)').removeClass('step-card-in').addClass('step-card-in-reverse');
                    // setTimeout($.proxy(function(){
                    // },this),250);
                    setTimeout($.proxy(function(){
                        $('.process-step-card.step-card-in-reverse').removeClass('step-card-in-reverse');
                        $('.process-step-card.step-card-in').removeClass('step-card-in');
                    },this),500);
                    setTimeout($.proxy(function(){
                        $('.process-step-card.step-card-out-reverse').removeClass('step-card-out-reverse');
                    },this),500);
                });
                $(this.getSelectors()).find('.process-controls .process-control-next').on('click',function(e){
                    e.preventDefault();
                    $(this).closest('.cards-container').find('.process-step-card:last-of-type').removeClass('step-card-in').addClass('step-card-out');
                    $(this).closest('.cards-container').find('.process-step-card:last-of-type').prev('.process-step-card').addClass('step-card-in');
                    setTimeout($.proxy(function(){
                        $(this).closest('.cards-container').find('.process-step-card:last-of-type').parent().prepend($(this).closest('.cards-container').find('.process-step-card:last-of-type'));
                    },this),250);
                    setTimeout($.proxy(function(){
                        $('.process-step-card.step-card-out').removeClass('step-card-out');
                    },this),250);
                    setTimeout($.proxy(function(){
                        $('.process-step-card.step-card-in').removeClass('step-card-in');
                    },this),500);
                });
            }
        }
    });
});
