define('templates',['handlebars'], function(Handlebars) {

var glob = ('undefined' === typeof window) ? global : window,

Handlebars = glob.Handlebars || require('handlebars');

this["JST"] = this["JST"] || {};

Handlebars.registerPartial("judgebox", this["JST"]["judgebox"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<a class=\"judgebox\" data-judge=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n	<div class=\"judgethumb\">\n		<img class=\"img-responsive\" src=\""
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" border=\"0\">\n        <div class=\"name-title-container\">	\n			<h5>"
    + alias4((helpers.formatName || (depth0 && depth0.formatName) || alias2).call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"formatName","hash":{},"data":data}))
    + "</h5>\n			<h6 class=\"title\">"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</h5>\n		</div>\n	</div>\n</a> ";
},"useData":true}));

Handlebars.registerPartial("judgegroup", this["JST"]["judgegroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgebox,depth0,{"name":"judgebox","data":data,"indent":"\t\t\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<article class=\"judgeGroup text-center border-box\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" data-title=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"container-fluid\">\n        <div class=\"row\">\n            <div class=\"row mobile-spacer white-row-mobile\">\n                <div class=\"col-xs-offset-1 col-xs-10 col-sm-offset-1 col-sm-10 col-md-8 col-md-offset-2\">\n                    <span class=\"judgeCount\">"
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.records : depth0)) != null ? stack1.length : stack1), depth0))
    + "</span>\n                    <h4 class=\"retired-load_"
    + alias4(((helper = (helper = helpers.cms || (depth0 != null ? depth0.cms : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cms","hash":{},"data":data}) : helper)))
    + "_h3\">"
    + alias4(((helper = (helper = helpers.descTitle || (depth0 != null ? depth0.descTitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"descTitle","hash":{},"data":data}) : helper)))
    + "</h4>\n                </div>\n            </div>\n            <div class=\"row white-row-mobile\">\n                <div class=\"col-xs-offset-1 col-xs-10 col-sm-offset-1 col-sm-10 col-md-8 col-md-offset-2 textColumn retired-load_"
    + alias4(((helper = (helper = helpers.cms || (depth0 != null ? depth0.cms : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cms","hash":{},"data":data}) : helper)))
    + "_content\">\n                	"
    + ((stack1 = ((helper = (helper = helpers.desc || (depth0 != null ? depth0.desc : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"desc","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n            <div class=\"row judgeGridContainer-wrap col-xs-12 col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1\">\n				<div class=\"judgeGridContainer\">\n					<div class=\"judgesGrid row\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "					</div>\n				</div>\n            </div>\n        </div>\n    </div>\n</article>";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("judgeinfo", this["JST"]["judgeinfo"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"judgeinfoWrap border-box\" data-judge=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n	<div class=\"row judge-detail\">\n		\n		<div class=\"col-md-3 col-md-offset-1\">\n			<img src=\""
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" class=\"img-responsive\" />\n		</div>\n		<div class=\"col-md-7\">	\n			<h3 class=\"name\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>					\n			<h4>"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</h4>					\n			<p>"
    + ((stack1 = ((helper = (helper = helpers.longDescription || (depth0 != null ? depth0.longDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longDescription","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n		</div>\n		\n	</div>		\n</div>			";
},"useData":true}));

Handlebars.registerPartial("judgeinfogroup", this["JST"]["judgeinfogroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgeinfo,depth0,{"name":"judgeinfo","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"row judgeInfoGroup\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"col-md-12 judgeTextContainer\">\n        <div class=\"judgeInner\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"nav\">\n		<span class=\"backToggle typeIcon visible-xs\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n		<div class=\"sideNavWrap judgesSideNav\"></div>\n	</div>\n</div>\n";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("newslistingentry", this["JST"]["newslistingentry"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"newsListingEntryRow row\" data-category=\""
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "\" data-news=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"col-md-2 hidden-xs hidden-sm\">\n    	<div class=\"news-listing-img-container\">\n        	<img src=\""
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" class=\"img-responsive\"/>\n        </div>\n    </div>\n    <div class=\"col-sm-12 col-md-8 layout-vertical-center listing-content\">\n    	<div class=\"listing-content-inner\">\n        	<h4>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h4>\n        	<div class=\"description\">\n        		"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n        	</div>\n        </div>\n    </div>\n    <div class=\"col-sm-12 col-md-2\">\n        <span class=\"newsTypeTag\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</span>\n        <span class=\"newsDate\">"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "</span>\n    </div>\n</div>";
},"useData":true}));

Handlebars.registerPartial("newspromobox", this["JST"]["newspromobox"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"newsGridPromoBox\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ");\" data-news=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"></div>\n<div class=\"newsBoxInfo\">\n	<span class=\"newsTypeTag\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</span></span><span class=\"newsDate\">"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "</span>\n	<h4>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h4>\n	<h5>"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h5>\n</div>";
},"useData":true}));

Handlebars.registerPartial("newspromocarousel", this["JST"]["newspromocarousel"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),"==",0,{"name":"ifComp","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                        <div class=\"item active\">\n                            <div class=\"img-wrap\" style=\"background-image: url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ")\"></div>\n                            <div class=\"details-wrap newsGridCarouselItem\" data-news=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                                <span class=\"category\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</span>\n                                <span class=\"date\">"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "</span>\n                                <h3 class=\"title\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n                                <p class=\"desc\">"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n                            </div>\n                        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                        <div class=\"item\">\n                            <div class=\"img-wrap\" style=\"background-image: url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ")\"></div>\n                            <div class=\"details-wrap newsGridCarouselItem\" data-news=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                                <span class=\"category\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</span>\n                                <span class=\"date\">"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "</span>\n                                <h3 class=\"title\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n                                <p class=\"desc\">"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n                            </div>\n                        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),"==",0,{"name":"ifComp","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                        <li data-target=\"#newsPromoGridCarousel\" data-slide-to=\""
    + container.escapeExpression(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"active\"></li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                        <li data-target=\"#newsPromoGridCarousel\" data-slide-to=\""
    + container.escapeExpression(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data}) : helper)))
    + "\"></li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"row\">\n    <div class=\"col-sm-12\">\n        <div id=\"newsPromoGridCarousel\" class=\"carousel slide\" data-ride=\"carousel\">\n            <div class=\"carousel-inner\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.promoNewsItems : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n\n            <a class=\"left carousel-control\" href=\"#newsPromoGridCarousel\" data-slide=\"prev\">\n                <span class=\"icon-icon-arrow-left-rounded\"></span>\n                <span class=\"sr-only\">Previous</span>\n            </a>\n            <a class=\"right carousel-control\" href=\"#newsPromoGridCarousel\" data-slide=\"next\">\n                <span class=\"icon-icon-arrow-right-rounded\"></span>\n                <span class=\"sr-only\">Next</span>\n            </a>\n            <ol class=\"carousel-indicators\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.promoNewsItems : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </ol>\n        </div>\n    </div>\n</div>";
},"useData":true}));

Handlebars.registerPartial("scoringintro", this["JST"]["scoringintro"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return " <div class=\"section pushSection layout-auto-height angleBottomRight\" data-bgimage=\"scoring-intro\">\n	<div class=\"introSection container\" data-title=\"\">\n		<!--<div class=\"load_scoring\">-->\n		<div class=\"row center-height\">\n			<div class=\"col-md-10 col-md-offset-1 content load_scoring_card1\">\n				<h1>Scoring Process</h1>\n				<h2>A careful selection of judges grouped into two categories; the Evaluation Panel and the Selection Committee.</h2>\n				<a class=\"intro-section-btn\" data-scrollto=\".scoreQuestion.section\">Interact with the Rubric<i class=\"icon-icon-arrow-down-rounded\"></i></a>		\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true}));

Handlebars.registerPartial("scoringitems", this["JST"]["scoringitems"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "						<div class=\"tick tickTop\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\"></div>\n						<div class=\"tick tickBottom\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\">"
    + alias3(((helper = (helper = helpers.score || (depth0 != null ? depth0.score : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"score","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"scoreQuestion section pushSection solid-gray-bg\" data-title=\"\" data-bgimage=\"scoring-body\">\n	<div class=\"container\">\n		<div class=\"row center-height\">\n			<a class=\"sbtn scoreCommentBtn primaryBlankBtn hidden-xs\">Leave Comments</a>\n			<h3 class=\"hidden-xs\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + " <span class=\"scoreRange\">(0-"
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + ")</span></h3>\n			<div class=\"text-center\">\n				<div class=\"helpText\"><div class=\"inner\"><h5>Help</h5>"
    + alias4(((helper = (helper = helpers.helpText || (depth0 != null ? depth0.helpText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"helpText","hash":{},"data":data}) : helper)))
    + "</div></div>\n				<div class=\"commentsText text-center\">\n					<textarea placeholder=\"Comments (minimum 50 characters): Explain your score and provide feedback to the participant.\"></textarea>\n					<div class=\"hidden-xs\">\n						<a class=\"btn\" href=\"#\">Submit Comments</a>\n					</div>\n				</div>\n			</div>\n			<div class=\"scoreHeader\">\n				<div class=\"scoreCounter hidden-xs\">\n					<span class=\"current\">"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"total\">"
    + alias4(((helper = (helper = helpers.total || (depth0 != null ? depth0.total : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total","hash":{},"data":data}) : helper)))
    + "</span>\n				</div>\n				<div class=\"scoreCounter scoreNav\">\n					<span class=\"prev\"><i class=\"icon-arrow-left-full\"></i></span>\n					<span class=\"next\"><i class=\"icon-arrow-right-full\"></i></span>\n				</div>\n				<div class=\"scoreQuestion\">\n					<span class=\"visible-xs\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"question-text\">"
    + alias4(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"help-question\">\n						<div class=\"icon-icon-help1\"></div>\n					</span>\n					<div class=\"icon-icon-close\"></div>\n				</div>\n			</div>\n			<div class=\"scoreDescription\">\n				<span></span>\n			</div>\n			<div class=\"scoreValue\">\n				<span class=\"valfollow\"></span>\n				<span class=\"valBorderLeft hidden-xs\"></span>\n				<span class=\"valBorderRight hidden-xs\"></span>\n			</div>\n			<div class=\"scoreSliderContainer\">\n				<div class=\"sliderTicks\">\n			    	<span class=\"visible-xs anchorLow\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			    	<span class=\"visible-xs anchorHigh\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n				</div>\n				<span class=\"traitEndL\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n				<div class=\"scoreSlider\">\n					<input id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"scoreSliderInput\" data-slider-id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "Slider\" type=\"text\" data-slider-min=\"0\" data-slider-max=\""
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + "\" data-slider-step=\"0.1\" data-slider-value=\""
    + alias4(((helper = (helper = helpers.initScore || (depth0 != null ? depth0.initScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"initScore","hash":{},"data":data}) : helper)))
    + "\"/>\n				</div>\n				<span class=\"traitEndR\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n\n		</div>\n		<!--<a class=\"btn scoreCommentBtn primaryBlankBtn hidden-xs hidden-sm \">Leave Comments</a>-->\n	</div>\n</div>";
},"useData":true,"useDepths":true}));

Handlebars.registerPartial("scoringlastcard", this["JST"]["scoringlastcard"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"lastCard section pushSection register layout-auto-height angleTopLeft\" data-bgimage=\"endcard\">\n\n</div>\n";
},"useData":true}));

Handlebars.registerPartial("stepcard", this["JST"]["stepcard"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "    				<a href=\""
    + ((stack1 = ((helper = (helper = helpers.btnLink || (depth0 != null ? depth0.btnLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnLink","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\" class=\"btn\">"
    + ((stack1 = ((helper = (helper = helpers.btnText || (depth0 != null ? depth0.btnText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"process-step-card\">\n	<div class=\"row\">\n		<div class=\"col-xs-12 col-xs-offset-0 col-sm-3 col-sm-offset-1\">\n			<div class=\"step-card-image\">\n				<img src=\""
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" />\n			</div>\n		</div>\n		<div class=\"col-xs-12 col-xs-offset-0 col-sm-6 col-sm-offset-1\">\n			<div class=\"step-card-content\">\n    			<h4>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h4>\n    			<p>"
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.btnText : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    		</div>\n    	</div>\n    </div>\n    <div class=\"process-step-next-wrap\">\n        <span class=\"counts\">"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + " <span class=\"count-divide\">/</span> "
    + alias4(((helper = (helper = helpers.total || (depth0 != null ? depth0.total : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total","hash":{},"data":data}) : helper)))
    + "</span>\n        <span class=\"next\">View Next Step</span>\n        <div class=\"process-step-next\"><i class=\"icon-icon-arrow-down-rounded\"></i></div>\n    </div>\n    \n\n</div>";
},"useData":true}));

Handlebars.registerPartial("timelineinfo", this["JST"]["timelineinfo"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "active";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "			<a class=\"btn alternate-btn\" href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = helpers.btntxt || (depth0 != null ? depth0.btntxt : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btntxt","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"item text-center "
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-period=\""
    + alias4(((helper = (helper = helpers.period || (depth0 != null ? depth0.period : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"period","hash":{},"data":data}) : helper)))
    + "\" data-period-length=\""
    + alias4(((helper = (helper = helpers.periodlength || (depth0 != null ? depth0.periodlength : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"periodlength","hash":{},"data":data}) : helper)))
    + "\">\n	<div class=\"inner-item\">\n		<h4>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h4>\n		<h6>"
    + ((stack1 = ((helper = (helper = helpers.subtitle || (depth0 != null ? depth0.subtitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subtitle","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h6>\n		"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n</div>";
},"useData":true}));

this["JST"]["dashboardlinks"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <a href=\""
    + alias4(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\">\n                <div class=\"linkText\">\n                    <img class=\"mobile-nav-icons\" src=\""
    + alias4(((helper = (helper = helpers.icon || (depth0 != null ? depth0.icon : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data}) : helper)))
    + "\" width=\"30\" style=\"margin-right: 5px;\">\n                    "
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\n                </div>\n            </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"dashboardLinksWrap\">\n    <div class=\"dashboardLinks\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.links : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"useData":true});

this["JST"]["faqsliderhorizontal"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda;

  return "            <div class=\"cards\" data-card-num=\""
    + container.escapeExpression((helpers.math || (depth0 && depth0.math) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),"+",1,{"name":"math","hash":{},"data":data}))
    + "\">\n                <div class=\"card-content\">\n                    <div class=\"card-content-inner\" style=\"background-image:url("
    + ((stack1 = alias1((depth0 != null ? depth0.imageUrl : depth0), depth0)) != null ? stack1 : "")
    + ");\">\n\n                    </div>\n                    <div class=\"card-content-inner-additional text-center stopOver layout-vertical-center\">\n                        <div class=\"answer-inner\">\n                        	<div>"
    + ((stack1 = alias1((depth0 != null ? depth0.answer : depth0), depth0)) != null ? stack1 : "")
    + "\n                        	</div>\n                        </div>\n                    </div>\n                    <div class=\"btn-more\">\n                        <span>?</span>\n                        <i class=\"material-icons\">close</i>\n                    </div>\n                </div>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "<i>&nbsp;</i>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"container\">\n    <div class=\"faqInnerWrap row\">\n        <button class=\"faq-cards-btn faq-cards-btn-prev hidden-xs\" id=\"faqsHorizontalCarouselLeftArrow\">\n            <span class=\"icon-icon-arrow-left-rounded\"></span>\n        </button>\n        <div class=\"col-xs-12\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n        <button class=\"faq-cards-btn faq-cards-btn-next hidden-xs\" id=\"faqsHorizontalCarouselRightArrow\">\n            <span class=\"icon-icon-arrow-right-rounded\"></span>\n        </button>\n    </div>\n</div>\n<div class=\"faq-cards-mobile-indicators visible-xs\">\n    "
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</div>";
},"useData":true});

this["JST"]["forgotpassword"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<i class=\"register-login-forms-close\"></i>\n\n<div class=\"formmessage layout-vertical-center\">\n	<div class=\"inner\">\n		<div class=\"modal-title\">\n			Forgot\n		</div>\n		<div class=\"contentModalWrap\">\n			<p>Please enter the email you registered with and then click send link.</p>\n		</div>\n	</div>\n</div>\n<div class=\"validationMessage\"></div>\n<div id=\"forgotPassword\" class=\"modal-container layout-vertical-center\">\n	<div class=\"inner\">\n		<!--<div class=\"modal-close icon-icon-close\"></div>\n		<div class=\"modal-title\">Forgot Password</div>-->\n		<div class=\"form message\" role=\"form\">\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<div class=\"form-group\">\n						<input name=\"email\" type=\"email\" class=\"form-control firstLoad\">\n						<span class=\"floating-label\">Email</span>\n					</div>\n				</div>\n			</div>\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<button id=\"submitForgotBtn\" class=\"submitForgotBtn btn btn-tertiary\" type=\"submit\" disabled=\"disabled\">Reset</button>\n				</div>\n			</div>\n			<div class=\"register_message\" style=\"display: none\">\n				<div class=\"col-xs-12\"></div>\n			</div>\n		</div>\n	</div>\n	\n	<div class=\"toggle-screen\">\n		<a href=\"#login\" class=\"modal-switch btn btn-tertiary\">I want to Login</a>\n	</div>\n\n</div>\n\n";
},"useData":true});

this["JST"]["formmessage"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "<div class=\"messageWrap\">\n	<div class=\"modal-close icon-icon-close\"></div>\n	<div class=\"modal-back icon-icon-arrow-left-block\"></div>\n    <div class=\"modal-title\">"
    + container.escapeExpression(((helper = (helper = helpers.messageTitle || (depth0 != null ? depth0.messageTitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"messageTitle","hash":{},"data":data}) : helper)))
    + "</div>\n    <div class=\"contentModalWrap\">\n        <p>"
    + ((stack1 = ((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n    </div>\n</div>\n\n<div class=\"toggle-screen\">\n	<a href=\"#login\" class=\"modal-switch\">I want to <strong>Login</strong></a>\n</div>";
},"useData":true});

this["JST"]["graphicmapmenu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                            <a class=\"sidebar-item\" href=\""
    + alias4(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\" style=\"top:"
    + alias4(helpers.lookup.call(alias1,helpers.lookup.call(alias1,(depths[1] != null ? depths[1].itemPositions : depths[1]),(data && data.index),{"name":"lookup","hash":{},"data":data}),"y",{"name":"lookup","hash":{},"data":data}))
    + "px;left:"
    + alias4(helpers.lookup.call(alias1,helpers.lookup.call(alias1,(depths[1] != null ? depths[1].itemPositions : depths[1]),(data && data.index),{"name":"lookup","hash":{},"data":data}),"x",{"name":"lookup","hash":{},"data":data}))
    + "px;\">"
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "</a>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=helpers.helperMissing, alias4="function";

  return "                            <div class=\"sidebar-item-wrap\" style=\"top:"
    + alias2(helpers.lookup.call(alias1,helpers.lookup.call(alias1,(depths[1] != null ? depths[1].itemPositions : depths[1]),(data && data.index),{"name":"lookup","hash":{},"data":data}),"y",{"name":"lookup","hash":{},"data":data}))
    + "px;left:"
    + alias2(helpers.lookup.call(alias1,helpers.lookup.call(alias1,(depths[1] != null ? depths[1].itemPositions : depths[1]),(data && data.index),{"name":"lookup","hash":{},"data":data}),"x",{"name":"lookup","hash":{},"data":data}))
    + "px;\">\n                                <a class=\"sidebar-item\" alt=\""
    + alias2(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\">"
    + alias2(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "</a>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                                    <a class=\"sidebar-item sidebar-item-unfold\" href=\""
    + alias4(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                <a href=\"/logout.aspx\" class=\"logoutBtn\">I want to Logout<i class=\"icon-arrow-right\"></i></a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "                    <a href=\"#login\" class=\"loginBtn\">I want to Login<i class=\"icon-arrow-right\"></i></a>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.submissionClosed : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "                        <a href=\"#login\" class=\"loginBtn\">Login<i class=\"icon-arrow-right\"></i></a>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                        <a href=\"#register\" class=\"registerBtn\">Register Now<i class=\"icon-arrow-right\"></i></a>\n                        <span class=\"txt\">OR</span>\n                        <a href=\"#login\" class=\"loginBtn\">Login<i class=\"icon-arrow-right\"></i></a>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "                <h3 class=\"daytext\">Registration Closed</h3>\n                <a href=\"#login\" class=\"registerBtn daysPromoRegisterBtn retire-modal-trigger\">Login<i class=\"icon-arrow-right\"></i></a>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"daysCount countText\"></span>\n                <h3 class=\"daytext\"><span class=\"dayCountWord\">Days</span> to Register</h3>\n                <a href=\"#register\" class=\"registerBtn daysPromoRegisterBtn retire-modal-trigger\">Register Now<i class=\"icon-arrow-right\"></i></a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"mainMenu\" style=\"display:none;\">\n    <div id=\"centerMenuContent\"></div>\n    <div id=\"sideMenu\">\n        <div class=\"background\">\n            <ul data-pxcontainer=\"true\">\n                <li class=\"layer\" data-depth=\"0.45\">\n                    <img src=\"img/graphicmap_parallax_1.png\" />\n                </li>\n                <li class=\"layer\" data-depth=\"0.32\">\n                    <img src=\"img/graphicmap_parallax_2.png\" />\n                </li>\n                <li class=\"layer\" data-depth=\"0.2\">\n                    <img src=\"img/graphicmap_parallax_3.png\" />\n                </li>\n            </ul>\n        </div>\n        <div class=\"menuWrap\">\n            <div id=\"sidebar\" data-pxcontainer=\"true\">\n                <div class=\"sidebar-item-layer layer\" data-depth=\"0.07\" data-pxnomobile=\"true\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"closeWrap\"></div>\n            </div>\n        </div>\n        <div class=\"mobile-login\">\n\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(alias1,"loggedin",{"name":"checkUser","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div id=\"actionMenu\">\n        <div class=\"daysPromo\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.submissionClosed : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.program(19, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"daysLeftPromo\">\n            <span class=\"daysLeft\"></span>\n            <h3>Days to Submit</h3>\n        </div>\n        <div class=\"validationMessage\"></div>\n        <div class=\"actionContent\"></div>\n        <div class=\"actionFooter\">\n            <a href=\"#login\" class=\"loginBtn retire-modal-trigger\">I want to Login<i class=\"icon-arrow-right\"></i></a>\n            <a href=\"#register\" class=\"registerBtn retire-modal-trigger\">I want to Register!<i class=\"icon-arrow-right\"></i></a>\n            <a href=\"/logout.aspx\" class=\"logoutBtn retire-modal-trigger\">I want to Logout<i class=\"icon-arrow-right\"></i></a>\n        </div>\n    </div>\n</div>";
},"useData":true,"useDepths":true});

this["JST"]["judgesample"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"row\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgebox,depth0,{"name":"judgebox","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.bsRow || (depth0 && depth0.bsRow) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),4,depth0,{"name":"bsRow","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});

this["JST"]["judgespage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgegroup,depth0,{"name":"judgegroup","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgeinfogroup,depth0,{"name":"judgeinfogroup","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"judgeTypeBar\" style=\"opacity:0;\" data-0=\"opacity:0;bottom:0px;\" data-600=\"opacity:0;\" data-700=\"opacity:1;\" data-end=\"bottom:60px;\" data-60-end=\"bottom:0px;\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.judgeGroups : stack1),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <span class=\""
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(data && data.index),0,{"name":"ifCond","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "enabled";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"judges\" class=\"container\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.judgeGroups : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n\n<div id=\"judgesDetail\">\n    <div class=\"container\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.judgeGroups : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.showTypeBar : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});

this["JST"]["lastcard"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.url : depth0),"false",{"name":"ifCond","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "							<a>\n															<div class=\"layout-vertical-center\">\n																	<img src=\""
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" />\n															</div>\n							</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "							<a href=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n															<div class=\"layout-vertical-center\">\n																	<img src=\""
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" />\n															</div>\n							</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"container\">\n	<div class=\"row\">\n		<div class=\"col-md-12\">\n			<div class=\"registerBox\" data-pxscroll=\"0.3\">\n				<h3>Register</h3>\n				<div class=\"retired-load_all_last_card\">\n					<p>What's your big idea? Together, let's breathe new life into IPF.</p>\n				</div>\n				<div class=\"lastBoxButton\">\n					<a href=\"#register\" class=\"btn lastCardRegisterBtn retire-modal-trigger\"><span class=\"dayCount\"></span> <span class=\"dayCountWord\">days</span> to register</a>\n				</div>\n			</div>\n			<div class=\"sponsorBoxx\" data-pxscroll=\"0.5\">\n				<div class=\"retired-load_all_challenge_partners_card\">\n					<h3>Challenge Partners</h3>\n					<p>Three Lakes Partners has joined forces with three world-class organizations to collaborate on the $1,000,000 IPF Catalyst Challenge: MATTER, a healthcare technology incubator and innovation hub; IDEO, an award-winning global design and innovation consultancy; and Common Pool, renowned for creating competitions to solve the world’s most challenging problems.</p>\n				</div>\n				<div class=\"sponsorBoxInner\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.sponsors : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				</div>\n			</div>\n			<div class=\"infoBox\" data-pxscroll=\"0.7\">\n				<div class=\"social-wrap\">\n					<a class=\"facebook\" href=\"https://www.facebook.com/IPFCatalyst/\" target=\"_blank\"><i class=\"icon icon-icon-facebook\"></i></a>\n					<a class=\"twitter\" href=\"https://twitter.com/ipfcatalyst\" target=\"_blank\"><i class=\"icon icon-icon-twitter\"></i></a>\n				</div>\n				<div class=\"links-wrap\">\n					<a href=\"#privacy-policy\">Privacy Policy</a>\n					<a href=\"#terms-conditions\">Terms &amp; Conditions</a>\n					<a href=\"mailto:questions@ipfcatalyst.com\">Contact</a>\n				</div>\n				<div class=\"lastcardRights\">\n                    <p>Stock photos. Posed by models and not actual patients.<br/>Challenge designed by <a href=\"http://commonpool.org/\" target=\"_blank\">Common Pool</a>; powered by <a href=\"https://www.rampit.com/\" target=\"_blank\">Rampit</a>. &copy; 2017 Rampit.</p>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true});

this["JST"]["login"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data}) : helper)))
    + "\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<i class=\"register-login-forms-close\"></i>\n\n<div class=\"formmessage layout-vertical-center\">\n	<div class=\"inner\">\n		<div class=\"modal-title\">\n			Login\n		</div>\n		<div class=\"contentModalWrap\">\n			<p>To complete or view your application, please login.</p>\n		</div>\n	</div>\n</div>\n<div class=\"validationMessage\"></div>\n<div id=\"login\" class=\"modal-container layout-vertical-center\">\n	<div class=\"inner\">\n		<!--<div class=\"modal-close icon-icon-close\"></div>\n		<div class=\"modal-title\">Login</div>-->\n		<div class=\"form login\" role=\"form\">\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<div class=\"form-group\">\n						<input name=\"email\" type=\"email\" id=\"\" placeholder=\"\" class=\"form-control firstLoad\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.email : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n						<span class=\"floating-label\">Email</span>\n					</div>\n				</div>\n			</div>\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<div class=\"form-group\">\n						<input name=\"password\" type=\"password\" id=\"\" placeholder=\"\" class=\"form-control\">\n						<span class=\"floating-label\">Password</span>\n					</div>\n				</div>\n			</div>\n		\n			<div class=\"row modal-row-pad social-login-row\">\n				<span class=\"subtext above-social-login\">\n					<a href=\"#register\">Don't have an account?</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href=\"#forgot\">Forgot Password?</a>\n				</span>\n			\n			\n			\n			</div>\n		\n			<div class=\"row modal-row-pad below-social-login-btn\">\n				<div class=\"col-xs-12\">\n					<button id=\"signinButton\" class=\"signinButton btn btn-tertiary\" type=\"submit\" disabled=\"disabled\">Login</button>\n				</div>\n			</div>\n		</div>\n	</div>\n	\n	<div class=\"socialLogin\">\n		<div class=\"row modal-row-pad\">\n			<div class=\"socialLinks\">\n				<a id=\"facebookButton\" href=\"#\">\n					<span class=\"bg\"></span>\n					<span class=\"txt hidden-sm\">Login with Facebook</span>\n				</a>\n				<a id=\"googleButton\" href=\"#\">\n					<span class=\"bg\"></span>\n					<span class=\"txt hidden-sm\">Login with Google</span>\n				</a>\n			\n			</div>\n		</div>\n	</div>\n</div>\n\n";
},"useData":true});

this["JST"]["navbar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "						<span class=\"daysToRegister hidden-xs\">\n							<a href=\"/pw\" class=\"btn solid navreg retire-modal-trigger\">Return to Prizeware</a>\n							<a class=\"btn solid modal-close\">\n								<span>Back To <strong class=\"activePageTitle\">Home</strong></span>\n							</a>\n						</span>\n			\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                            <span class=\"daysToRegister hidden-xs\">\n                                <a href=\"#login\" class=\"btn solid navreg retire-modal-trigger\">Login</a>\n                                <a class=\"btn solid modal-close\">\n                                    <span>Back To <strong class=\"activePageTitle\">Home</strong></span>\n                                </a>\n                            </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitRegTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                                <span class=\"daysToRegister hidden-xs\">\n                                    <a href=\"#register\" class=\"btn solid navreg retire-modal-trigger\">\n                                        <span class=\"dayCount\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitRegTimeLeft : stack1), depth0))
    + "</span>&nbsp;<span><span class=\"dayCountWord\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitRegTimeFrame : stack1), depth0))
    + "</span> to <strong>Register</strong></span>\n                                    </a>\n                                    <a class=\"btn solid modal-close\">\n                                        <span>Back To <strong class=\"activePageTitle\">Home</strong></span>\n                                    </a>\n                                </span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                                <span class=\"daysToRegister hidden-xs retire-modal-trigger\">\n                                    <a href=\"#login\" class=\"btn solid navreg retire-modal-trigger\">Login</a>\n                                    <a class=\"btn solid modal-close\">\n                                        <span>Back To <strong class=\"activePageTitle\">Home</strong></span>\n                                    </a>\n                                </span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<nav id=\"mainNavbar\" class=\"navbar navbar-default layout-vertical-center\" role=\"navigation\">\n    <div class=\"container\">\n		<div class=\"row\">\n		\n			<div class=\"col-xs-8 col-sm-6 col-md-4\">\n				<div class=\"navbar-header layout-vertical-center\">\n					<a class=\"navbar-brand anim\" href=\"/#home\"></a>\n					<a class=\"navbar-brand-sub\" href=\"/#home\"></a>\n				</div>\n        	</div>\n\n			<div class=\"col-xs-4 col-sm-6 col-md-8\">\n				<div class=\"dashboard-menu-container\">\n				\n					<div class=\"dashboardToggle dropdown\">\n						<a href=\"\">\n							<div class=\"icon hamburger-to-close\">\n								<div class=\"hamburger-middle\"></div>\n							</div>\n						</a>\n					</div>\n					\n					<div class=\"dashboard-menu-social-share layout-vertical-center\">\n                        <i class=\"material-icons social-expand-hover-icon\">share</i>\n						<ul class=\"social-share-expanded\">\n							<li class=\"dashboard-share-social-icon facebook-share socialShareCounter\" data-action=\"facebook\"></li>\n							<li class=\"dashboard-share-social-icon google-share socialShareCounter\" data-action=\"google\"></li>\n							<li class=\"dashboard-share-social-icon twitter-share socialShareCounter\" data-action=\"twitter\"></li>\n						</ul>\n                        <div class=\"mobile-close-social\">\n                            <i class=\"icon-icon-close\"></i>\n                        </div>\n					</div>\n		\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"loggedin",{"name":"checkUser","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "		\n		\n	\n				</div>\n			<div>\n\n		</div>\n    </div>\n</nav>";
},"useData":true});

this["JST"]["newspage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.newspromocarousel,depth0,{"name":"newspromocarousel","data":data,"indent":"\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.promoNewsItems : depth0)) != null ? stack1["0"] : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "		<div class=\"row\">\n			<div class=\"col-sm-12\">\n				<div class=\"row newsPromoGrid\">\n"
    + ((stack1 = container.invokePartial(partials.newspromobox,((stack1 = (depth0 != null ? depth0.promoNewsItems : depth0)) != null ? stack1["0"] : stack1),{"name":"newspromobox","data":data,"indent":"\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "				</div>\n			</div>\n		</div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return "					<div class=\"tab\">"
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.newslistingentry,depth0,{"name":"newslistingentry","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.usecarousel : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n<div class=\"row\">\n	<div class=\"col-sm-12\">\n		<div class=\"row newsListing\">\n			<div class=\"newsListingTabs\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.categories : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</div>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.regularNewsItems : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n</div>\n\n\n<div class=\"newsDetailContainer\"></div>";
},"usePartial":true,"useData":true});

this["JST"]["newspost"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"newsinfoWrap border-box\" data-news=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n    <div class=\"row news-detail\">\n        <div class=\"col-md-5\">\n            <div class=\"newsImageWrapLarge\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + ");\"></div>\n        </div>\n        <div class=\"col-md-6 inner-scrollable\">\n            <span>"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</span>\n            <h3 class=\"name\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n            <h4>"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</h4>\n            <p>"
    + ((stack1 = ((helper = (helper = helpers.longDescription || (depth0 != null ? depth0.longDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longDescription","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["processsteps"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.stepcard,depth0,{"name":"stepcard","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"process-container\" data-pxscroll=\"0.5\">\n    <div class=\"process-intro-section\">\n        <div class=\"container\">\n            <div class=\"row\">\n                <div class=\"col-md-3\"></div>\n                <div class=\"col-md-6\">\n                    <div class=\"retired-load_home_process_card\">\n                        <h3>What is the IPF Catalyst Challenge?</h3>\n                        <p>We’re looking for the best and brightest minds who are passionate about problem solving to develop real, executable solutions to help change the many quality-of-life issues affecting IPF patients and their families. The ABOUT THE CHALLENGE page can provide you with further background information, and valuable resources about IPF and the Challenge. Learn how to participate below, with this quick walkthrough of the IPF Catalyst Challenge registration and application process.<br /></p>\n                    </div>\n                    <span class=\"process-intro-separator\"></span>\n                </div>\n                <div class=\"col-md-3\"></div>\n            </div>\n        </div>\n    </div>\n    <div class=\"cards-container\">\n        <div class=\"container\">\n            <div class=\"row\">\n                <div class=\"col-md-12 processStepCardWrap\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.steps : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n            </div>\n            \n            <div class=\"process-controls sideNav\">\n				<span class=\"process-control-prev prev hidden-xs\">\n					<i class=\"icon-icon-arrow-up-rounded\"></i>\n				</span>\n				<span class=\"process-control-next next\">\n					<i class=\"icon-icon-arrow-down-rounded\"></i>\n				</span>\n			</div>\n            \n        </div>\n        \n    </div>\n</div>";
},"usePartial":true,"useData":true});

this["JST"]["register"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.fname || (depth0 != null ? depth0.fname : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fname","hash":{},"data":data}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.lname || (depth0 != null ? depth0.lname : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"lname","hash":{},"data":data}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data}) : helper)))
    + "\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<i class=\"register-login-forms-close\"></i>\n<div class=\"formmessage layout-vertical-center\">\n	<div class=\"inner\">\n		<div class=\"modal-title\">\n			Register\n		</div>\n		<div class=\"contentModalWrap\">\n			<p>Please register in order to participate!</p>\n		</div>\n	</div>\n</div>\n<div class=\"validationMessage\"></div>\n<div id=\"register\" class=\"modal-container layout-vertical-center\">\n	<div class=\"inner\">\n		<!--<div class=\"modal-close icon-icon-close\"></div>\n		<div class=\"modal-title\">Register</div>-->\n		<div class=\"form\" role=\"form\">\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<div class=\"form-group\">\n						<input name=\"fname\" type=\"text\" id=\"\" placeholder=\"\" class=\"form-control firstLoad\" required=\"required\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fname : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n						<span class=\"floating-label\">First Name</span>\n					</div>\n				</div>\n			</div>\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<div class=\"form-group\">\n						<input name=\"lname\" type=\"text\" id=\"\" placeholder=\"\" class=\"form-control\" required=\"required\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.lname : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n						<span class=\"floating-label\">Last Name</span>\n					</div>\n				</div>\n			</div>\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<div class=\"form-group\">\n						<input name=\"email\" type=\"text\" id=\"\" placeholder=\"\" class=\"form-control\" required=\"required\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.email : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n						<span class=\"floating-label\">Email</span>\n					</div>\n				</div>\n			</div>\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<div class=\"form-group\">\n						<input name=\"password\" type=\"password\" id=\"\" placeholder=\"\" class=\"form-control\" required=\"required\">\n						<span class=\"floating-label\">Password</span>\n					</div>\n				</div>\n			</div>\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\" >\n					<div class=\"form-group checkGroup\">\n					  <div class=\"form-control\">\n						<input name=\"chkAgree\" id=\"chkAgree\" type=\"checkbox\" required=\"required\">\n						<label for=\"chkAgree\"></label>\n						<span>\n							I accept the <a href=\"/#terms-conditions\">Terms & Conditions</a>\n						</span>\n					  </div>\n					</div>\n					<!-- Hidden Fields -->\n					<input name=\"type\" type=\"hidden\" />\n				</div>\n			</div>\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<button id=\"submitRegisterBtn\" class=\"submitRegisterBtn btn btn-tertiary\" type=\"submit\" disabled=\"disabled\">Register</button> <span class=\"toggle-span\">or</span> <a href=\"#login\" class=\"btn btn-tertiary modal-switch\">Login</a>\n				</div>\n			</div>\n			<div class=\"register_message\" style=\"display: none\">\n				<div class=\"col-xs-12\">\n				</div>\n			</div>\n		</div>\n    </div>\n</div>\n\n";
},"useData":true});

this["JST"]["resetpassword"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.regEmail || (depth0 != null ? depth0.regEmail : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"regEmail","hash":{},"data":data}) : helper)))
    + "\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"formmessage layout-vertical-center\">\n    <div class=\"inner\">\n        <div class=\"modal-title\">\n            Reset\n        </div>\n        <div class=\"contentModalWrap\">\n            <p>Please reset your password.</p>\n        </div>\n    </div>\n</div>\n<div class=\"validationMessage\"></div>\n<div id=\"resetPassword\" class=\"modal-container layout-vertical-center\">\n    <div class=\"inner\">\n        <div class=\"form message\" role=\"form\">\n            <div class=\"row\">\n                <div class=\"col-xs-12\">\n                    <div class=\"form-group\">\n                        <input name=\"email\" type=\"email\" id=\"\" class=\"form-control firstLoad\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.regEmail : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                        <span class=\"floating-label\">Email</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row\">\n                <div class=\"col-xs-12\">\n                    <div class=\"form-group\">\n                        <input name=\"password\" type=\"password\" class=\"form-control\" autocomplete=\"false\">\n                        <span class=\"floating-label\">Password</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row\">\n                <div class=\"col-xs-12\">\n                    <button id=\"resetPasswordButton\" class=\"btn btn-tertiary\" type=\"submit\" disabled=\"disabled\">Reset Password</button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["scoring"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "						<div class=\"tick tickTop\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\"></div>\n						<div class=\"tick tickBottom\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\">"
    + alias3(((helper = (helper = helpers.score || (depth0 != null ? depth0.score : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"score","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"scoreQuestion section pushSection solid-gray-bg\" data-title=\"\">\n	<div class=\"container\">\n		<div class=\"row center-height\">\n			<a class=\"sbtn scoreCommentBtn primaryBlankBtn hidden-xs\">Leave Comments</a>\n			<h4>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + " <span class=\"scoreRange\">(0-"
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + ")</span></h4>\n			<div class=\"text-center\">\n				<div class=\"helpText\"><div class=\"inner\"><h5>Help</h5>"
    + alias4(((helper = (helper = helpers.helpText || (depth0 != null ? depth0.helpText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"helpText","hash":{},"data":data}) : helper)))
    + "</div></div>\n				<div class=\"commentsText text-center\">\n					<textarea placeholder=\"Comments (minimum 50 characters): Explain your score and provide feedback to the participant.\"></textarea>\n					<div class=\"hidden-xs\">\n						<a class=\"btn\" href=\"#\">Submit Comments</a>\n					</div>\n				</div>\n			</div>\n			<div class=\"scoreHeader\">\n				<div class=\"scoreCounter hidden-xs\">\n					<span class=\"current\">"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"total\">"
    + alias4(((helper = (helper = helpers.total || (depth0 != null ? depth0.total : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total","hash":{},"data":data}) : helper)))
    + "</span>\n				</div>\n				<div class=\"scoreCounter scoreNav\">\n					<span class=\"prev\"><i class=\"icon-arrow-left-full\"></i></span>\n					<span class=\"next\"><i class=\"icon-arrow-right-full\"></i></span>\n				</div>\n				<div class=\"scoreQuestion\">\n					<span class=\"question-text\">"
    + alias4(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper)))
    + "</span>\n					<div class=\"icon-icon-close\"></div>\n				</div>\n			</div>\n			<div class=\"scoreDescription\">\n				<span></span>\n			</div>\n			<div class=\"scoreValue\">\n				<span class=\"valfollow\"></span>\n				<span class=\"valBorderLeft hidden-xs\"></span>\n				<span class=\"valBorderRight hidden-xs\"></span>\n			</div>\n			<div class=\"scoreSliderContainer\">\n				<div class=\"sliderTicks\">\n			    	<span class=\"visible-xs anchorLow\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			    	<span class=\"visible-xs anchorHigh\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n				</div>\n				<span class=\"traitEndL\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n				<div class=\"scoreSlider\">\n					<input id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"scoreSliderInput\" data-slider-id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "Slider\" type=\"text\" data-slider-min=\"0\" data-slider-max=\""
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + "\" data-slider-step=\"0.1\" data-slider-value=\""
    + alias4(((helper = (helper = helpers.initScore || (depth0 != null ? depth0.initScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"initScore","hash":{},"data":data}) : helper)))
    + "\"/>\n				</div>\n				<span class=\"traitEndR\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n\n		</div>\n		<!--<a class=\"btn scoreCommentBtn primaryBlankBtn hidden-xs hidden-sm \">Leave Comments</a>-->\n	</div>\n</div>";
},"useData":true,"useDepths":true});

this["JST"]["sidenav"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"sideNav\">\n    <span class=\"prev\">\n    	<i class=\"icon-icon-arrow-up-rounded\"></i>\n    </span>\n    <span class=\"counter layout-vertical-center\">\n        	<div class=\"counter-inner\">\n            	<div class=\"currentCount\">1</div>\n            	<span class=\"divider\"></span>\n            	<div class=\"totalCount\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.records : depth0)) != null ? stack1.length : stack1), depth0))
    + "</div>\n            </div>\n        </span>\n    <span class=\"next\">\n    	<i class=\"icon-icon-arrow-down-rounded\"></i>\n    </span>\n</div>";
},"useData":true});

this["JST"]["social"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<li><div class=\"socialicon socialicon-"
    + alias4(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data}) : helper)))
    + "\"></div><a href=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</a></li>";
},"useData":true});

this["JST"]["timeline"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.timelineinfo,depth0,{"name":"timelineinfo","data":data,"indent":"\t\t\t\t\t ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "		\n			<div class=\"timelinePoint "
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n				data-toggle=\"tooltip\" data-placement=\"top\" data-html=\"true\" data-trigger=\"hover\" title=\"<span>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span><span>"
    + ((stack1 = ((helper = (helper = helpers.balloonsubtitle || (depth0 != null ? depth0.balloonsubtitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"balloonsubtitle","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\"\n				style=\"left:"
    + alias4((helpers.math || (depth0 && depth0.math) || alias2).call(alias1,(helpers.math || (depth0 && depth0.math) || alias2).call(alias1,(depth0 != null ? depth0.timestamp : depth0),"-",(depths[1] != null ? depths[1].start : depths[1]),{"name":"math","hash":{},"data":data}),"*",(depths[1] != null ? depths[1].msIncrement : depths[1]),{"name":"math","hash":{},"data":data}))
    + "%;\">\n			</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <div class=\"connectLine\" data-period=\""
    + container.escapeExpression(((helper = (helper = helpers.period || (depth0 != null ? depth0.period : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"period","hash":{},"data":data}) : helper)))
    + "\"\n                 style=\"left:-1028px;width:1000px\">\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "				<div class=\"connectLine\" data-period=\""
    + alias3(((helper = (helper = helpers.period || (depth0 != null ? depth0.period : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"period","hash":{},"data":data}) : helper)))
    + "\" style=\"\n					left: "
    + alias3((helpers.math || (depth0 && depth0.math) || alias2).call(alias1,(helpers.math || (depth0 && depth0.math) || alias2).call(alias1,(helpers.prev || (depth0 && depth0.prev) || alias2).call(alias1,(depths[1] != null ? depths[1].timelinePoints : depths[1]),(data && data.index),"timestamp",{"name":"prev","hash":{},"data":data}),"-",(depths[1] != null ? depths[1].start : depths[1]),{"name":"math","hash":{},"data":data}),"*",(depths[1] != null ? depths[1].msIncrement : depths[1]),{"name":"math","hash":{},"data":data}))
    + "%;\n					width:calc("
    + alias3((helpers.math || (depth0 && depth0.math) || alias2).call(alias1,(helpers.math || (depth0 && depth0.math) || alias2).call(alias1,(depth0 != null ? depth0.timestamp : depth0),"-",(helpers.prev || (depth0 && depth0.prev) || alias2).call(alias1,(depths[1] != null ? depths[1].timelinePoints : depths[1]),(data && data.index),"timestamp",{"name":"prev","hash":{},"data":data}),{"name":"math","hash":{},"data":data}),"*",(depths[1] != null ? depths[1].msIncrement : depths[1]),{"name":"math","hash":{},"data":data}))
    + "%);\n					\">\n				</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "first active";
},"10":function(container,depth0,helpers,partials,data) {
    return "last";
},"12":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"timeline-toggle\">\n            <div class=\"detail\">\n                <i class=\"material-icons\">list</i> detail view\n            </div>\n            <div class=\"overview\">\n                <i class=\"material-icons\">apps</i> overview\n            </div>\n        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"container\">\n<div class=\"timelineContainer\">\n	<div id=\"timelineInfo\" class=\"timelineInfoContainer carousel slide layout-vertical-center\" data-interval=\"false\">\n		<div class=\"timelineWrap\">\n			<div class=\"carousel-inner timelineInfo\" role=\"listbox\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.timelinePoints : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</div>\n		</div>\n		<a class=\"timelineNav carousel-control left hidden-xs hidden-sm\" data-slide=\"prev\" data-target=\"#timelineInfo\"><div class=\"inner layout-vertical-center\"><i class=\"icon-icon-arrow-right-rounded\"></i></div></a>\n		<a class=\"timelineNav carousel-control right hidden-xs hidden-sm\" data-slide=\"next\" data-target=\"#timelineInfo\"><div class=\"inner layout-vertical-center right\"><i class=\"icon-icon-arrow-right-rounded\"></i></div></a>\n	</div>\n	<div class=\"timeline-bottom\">\n		<div class=\"timeline\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.timelinePoints : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	    </div>\n\n	    <div class=\"timeline-calendar\"><ul></ul></div>\n	    <!-- TODO work timeline js into injecting titles into class names as opposed to htags -->\n	    <div class=\"timeline-period\"><h3></h3><h6></h6></div>\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showOverview : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n</div>";
},"usePartial":true,"useData":true,"useDepths":true});

this["JST"]["validationmessage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <i class=\"register-login-forms-close\"></i>\n    <div class=\"formmessage layout-vertical-center\">\n        <div class=\"inner\">\n            <div class=\"modal-title\">\n                "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.customActionPanel : depth0)) != null ? stack1.title : stack1), depth0))
    + "\n            </div>\n            <div class=\"contentModalWrap\">\n                <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.customActionPanel : depth0)) != null ? stack1.message : stack1), depth0))
    + "</p>\n            </div>\n        </div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasError : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"btn btn-tertiary \" href=\"#login\">Back to Login</a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasError : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"btn btn-tertiary \" href=\"#login\">Login</a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasError : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"btn btn-tertiary \" href=\"#register\">Register</a>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasError : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.customActionPanel : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div id=\"form-message\" class=\"modal-container layout-vertical-center\">\n    <div class=\"inner\">\n        <div class=\"contentModalWrap\">\n            <p>"
    + ((stack1 = ((helper = (helper = helpers.messageTitle || (depth0 != null ? depth0.messageTitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"messageTitle","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n            <p>"
    + ((stack1 = ((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.originForm : depth0),"login",{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.originForm : depth0),"register",{"name":"ifCond","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.originForm : depth0),"forgotPassword",{"name":"ifCond","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.originForm : depth0),"resetPassword",{"name":"ifCond","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n\n\n";
},"useData":true});

this["JST"]["404"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"missingPageInner\" data-bgimage=\"default-bg\">\n    <div class=\"container\">\n        <div class=\"row layout-vertical-center\">\n        	<div class=\"col-xs-12\">\n				<div class=\"load_404_card1 layout-load-card\">\n					<h1>404 - Page Not Found</h1>\n					<p>This page could not be found.</p>\n				</div>\n				<a href=\"#home\" class=\"btn\">Take Me Back Home</a>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["sharedcard"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"cardWrap border-box\" data-id=\""
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n        	<!-- news -->\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.category : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "			<!-- end row detail -->\n		</div>\n		<!-- end cardWrap -->\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "				<div class=\"imageWrapLarge news-sidesocial-container hidden-xs\">\n					<span class=\"share\">Share</span>\n					<i class=\"icon-icon-facebook shareSocial\" data-action=\"facebook\" data-title=\""
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "\"></i>\n					<i class=\"icon-icon-twitter shareSocial\" data-action=\"twitter\" data-title=\""
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "\"></i>\n				</div>\n			\n				<div class=\"row detail\">	\n					<div class=\"col-md-offset-1 col-md-11 tplContentContainer\">\n						<div class=\"inner-scrollable news-inner-scrollable\">\n							<div class=\"visible-xs\">\n								<div class=\"imageWrapLarge news-sidesocial-container\">\n									<span class=\"share\">Share</span>\n									<i class=\"icon-icon-facebook shareSocial\" data-action=\"facebook\" data-title=\""
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "\"></i>\n									<i class=\"icon-icon-twitter shareSocial\" data-action=\"twitter\" data-title=\""
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "\"></i>\n								</div>\n							</div>\n							<span class=\"newsTypeTag\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</span>\n							<span class=\"cardWrapDate\">"
    + alias4(((helper = (helper = helpers.span || (depth0 != null ? depth0.span : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"span","hash":{},"data":data}) : helper)))
    + "</span>\n							<h4 class=\"name\">"
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "</h4>\n							<div class=\"content\">"
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n						</div>\n					</div>\n				</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "			<!-- judges -->\n				\n				<div class=\"imageWrapLarge hidden-xs hidden-sm\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ");\"></div>\n			\n				<div class=\"row detail\">	\n					<div class=\"col-md-offset-3 col-md-9 tplContentContainer\">\n						<div class=\"inner-scrollable\">\n							<div class=\"visible-xs visible-sm\">\n								<div class=\"imageWrapLarge\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ");\"></div>\n							</div>\n							<span class=\"judgeGroupSpan\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</span>\n							<h4 class=\"name\">"
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "</h4>\n							<h6 class=\"title\">"
    + alias4(((helper = (helper = helpers.h4 || (depth0 != null ? depth0.h4 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h4","hash":{},"data":data}) : helper)))
    + "</h6>\n							<div class=\"content\">"
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n						</div>\n					</div>\n				</div>\n				\n				\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"tplWrapper\">\n	<div class=\"tplNavWrapper\">\n		<i class=\"icon-close close\"></i>\n		<div class=\"sideNavWrap\" data-scrollparent=\".tplWrapper\"></div>\n    </div>\n    <div class=\"cardWrapper\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n</div>\n";
},"useData":true});

if (typeof exports === 'object' && exports) {module.exports = this["JST"];}

return this["JST"];

});
